/*fonts*/
@mixin font-face($style-name, $file:$style-name, $weight:normal, $style:normal) {
    $filepath: "/fonts/" + $file;
    @font-face {
        font-family: quote($style-name);
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
        url($filepath + ".woff") format('woff'),
        url($filepath + ".ttf")  format('truetype'),
        url($filepath + ".svg#" + $style-name + "") format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

@include font-face("Montserrat", "Montserrat-Regular", 400, normal);
@include font-face("Montserrat", "Montserrat-Medium", 500, normal);
@include font-face("Montserrat", "Montserrat-Semibold", 600, normal);
@include font-face("Montserrat", "Montserrat-Bold", 700, normal);
@include font-face("Moul", "Moul-Regular", 400, normal);



:root {
    --color-white: #fff;
    --color-black: #212121;
    --color-opacity: #797670;
    --color-primary:#D98C72;
    --color-primary-second:#824A64;
    --color-back-light:#F2ECE1;
    --color-back-dark: #E5D9C3;
    --color-light: #FAF7F3;
    --color-grey: #9E9B94;
    --Montserrat: 'Montserrat', sans-serif;
    --Moul: 'Moul', sans-serif;
}

*,*:before,
*:after {
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
*,body,html {
    -webkit-text-size-adjust: none;
    -webkit-appearance: none;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
button{
    border:none;
}
mark{
    -webkit-text-fill-color: initial;
    text-fill-color: transparent;
    background: none;
}

.img{
    &.contain img{
        object-fit: contain;
    }
    & > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
}

ul {
    list-style: none;
}

a{
    text-decoration: none;
    color: inherit;
    &:hover{
        text-decoration: none;
    }
}
button{
    background: transparent;
}
h1{
    margin: 0;
}
html {
    font-size: 10px;
    scroll-behavior: smooth;

}
@media only screen and (max-width: 1024px) {
    html {
        font-size: 8px;
    }
}

@media only screen and (max-width: 768px) {
    html {
        font-size: 6px;
    }
}

@media only screen and (max-width: 666px) {
    html {
        font-size: 10px;
    }
}