/*fonts*/
@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Regular.eot");
  src: url("/fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/Montserrat-Regular.woff") format("woff"), url("/fonts/Montserrat-Regular.ttf") format("truetype"), url("/fonts/Montserrat-Regular.svg#Montserrat") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Medium.eot");
  src: url("/fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/Montserrat-Medium.woff") format("woff"), url("/fonts/Montserrat-Medium.ttf") format("truetype"), url("/fonts/Montserrat-Medium.svg#Montserrat") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Semibold.eot");
  src: url("/fonts/Montserrat-Semibold.eot?#iefix") format("embedded-opentype"), url("/fonts/Montserrat-Semibold.woff") format("woff"), url("/fonts/Montserrat-Semibold.ttf") format("truetype"), url("/fonts/Montserrat-Semibold.svg#Montserrat") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Bold.eot");
  src: url("/fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Montserrat-Bold.woff") format("woff"), url("/fonts/Montserrat-Bold.ttf") format("truetype"), url("/fonts/Montserrat-Bold.svg#Montserrat") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Moul";
  src: url("/fonts/Moul-Regular.eot");
  src: url("/fonts/Moul-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/Moul-Regular.woff") format("woff"), url("/fonts/Moul-Regular.ttf") format("truetype"), url("/fonts/Moul-Regular.svg#Moul") format("svg");
  font-weight: 400;
  font-style: normal;
}

:root {
  --color-white: #fff;
  --color-black: #212121;
  --color-opacity: #797670;
  --color-primary:#D98C72;
  --color-primary-second:#824A64;
  --color-back-light:#F2ECE1;
  --color-back-dark: #E5D9C3;
  --color-light: #FAF7F3;
  --color-grey: #9E9B94;
  --Montserrat: 'Montserrat', sans-serif;
  --Moul: 'Moul', sans-serif;
}

*, *:before,
*:after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

*, body, html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  border: none;
}

mark {
  -webkit-text-fill-color: initial;
  text-fill-color: transparent;
  background: none;
}

.img.contain img {
  object-fit: contain;
}

.img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

button {
  background: transparent;
}

h1 {
  margin: 0;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 6px;
  }
}

@media only screen and (max-width: 666px) {
  html {
    font-size: 10px;
  }
}
